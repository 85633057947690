/* eslint-disable no-case-declarations */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import { useAuth } from '@dx-ui/framework-auth-provider';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEventMetrics, useGenerateURLs, MetricsEvent } from '../../hooks';
import {
  SessionContext,
  SessionContextTypes,
  LOGGED_IN_USER_TIMEOUT_SECONDS,
  COUNT_DOWN_SECONDS,
  IDLE_CHECK_INTERVAL,
  COUNT_DOWN_INTERVAL,
} from '../../lib';
import { useEventData } from '../../hooks/useEventData';
import { useQueryClient } from '@tanstack/react-query';
import { logError } from '@dx-ui/framework-logger';

type ISessionModalState = {
  leftSeconds: number;
  lastActiveTime: Date;
  timeLeft: string;
  ariaTimeLeft: string;
  showCountDownModal: boolean;
  showForceSignoutModal: boolean;
  showForceSignOutOnQUser: boolean;
  showConfirmSignoutModal: boolean;
};

type ICurrentState = {
  isAuthenticated: boolean;
  lastActiveTime: Date;
  showCountDownModal: boolean;
  showForceSignoutModal: boolean;
  showForceSignOutOnQUser: boolean;
  showConfirmSignoutModal: boolean;
};

type IModalButton = {
  e2eId: string;
  buttonText: string;
  buttonStyle: string;
  handler: () => void;
};

const SessionModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const events: string[] = ['mousemove', 'mousedown', 'keydown', 'touchstart', 'scroll'];
  const { sessionContext, setSessionContext } = React.useContext(SessionContext);
  const { isAuthenticated, isCorpUser, logout } = useAuth();
  const { signInUrl, lobbySignInURL } = useGenerateURLs();
  const { t } = useTranslation();
  const router = useRouter();
  const { setEventMetrics } = useEventMetrics();
  const language = router.locale;
  const countDownTimer = React.useRef<number>(0);
  const idleCheckTimer = React.useRef<number>(0);
  const [eventData] = useEventData();
  const queryClient = useQueryClient();

  const addLeadingZero = (value: number) => (value < 10 ? `0${value}` : `${value}`);

  const secondsToTime = (secs: number, getAdaText = false) => {
    const divisorForMinutes = secs % (60 * 60);
    const minutes = Math.floor(divisorForMinutes / 60);
    const divisorForSeconds = divisorForMinutes % 60;
    const seconds = Math.ceil(divisorForSeconds);
    if (getAdaText) {
      return `${minutes === 0 ? `` : t('modal.body.minute', { count: minutes })}${
        seconds === 0 ? `` : ` ${t('modal.body.second', { count: seconds })}`
      }`;
    }

    return `${addLeadingZero(minutes)}:${addLeadingZero(seconds)}`;
  };

  const initialState: ISessionModalState = {
    leftSeconds: COUNT_DOWN_SECONDS,
    lastActiveTime: new Date(),
    timeLeft: secondsToTime(COUNT_DOWN_SECONDS),
    ariaTimeLeft: secondsToTime(COUNT_DOWN_SECONDS, true),
    showCountDownModal: false,
    showForceSignoutModal: false,
    showForceSignOutOnQUser: false,
    showConfirmSignoutModal: false,
  };

  const [state, setState] = React.useState<ISessionModalState>(initialState);
  // prevState is required as a work arround to access current state properties inside timer functions
  const prevState = React.useRef<ICurrentState>({
    isAuthenticated,
    lastActiveTime: new Date(),
    showCountDownModal: false,
    showForceSignoutModal: false,
    showForceSignOutOnQUser: false,
    showConfirmSignoutModal: false,
  });

  const signOut = async () => {
    sessionStorage.clear();
    setEventMetrics(MetricsEvent.SIGN_OUT_ONLY);
    queryClient.clear();
    await logout();
  };

  const handleEvent = () => {
    setState((currentState) => ({ ...currentState, lastActiveTime: new Date() }));
  };

  const attachEvents = () => {
    events.forEach((event) => {
      window.addEventListener(event, handleEvent, true);
    });
  };

  const removeEvents = () => {
    events.forEach((event) => {
      window.removeEventListener(event, handleEvent, true);
    });
  };

  const resetCountDownTimer = () => {
    if (countDownTimer.current) {
      clearInterval(countDownTimer.current);
      countDownTimer.current = 0;
    }

    setSessionContext(SessionContextTypes.None);
    setState(initialState);
  };

  const countDown = () => {
    setState((currentState) => {
      const seconds = currentState.leftSeconds - 1;

      if (seconds === 0) {
        setSessionContext(SessionContextTypes.SessionTimeout);
        if (countDownTimer.current) {
          clearInterval(countDownTimer.current);
          countDownTimer.current = 0;
        }

        return {
          ...currentState,
          showCountDownModal: false,
        };
      }

      return {
        ...currentState,
        timeLeft: secondsToTime(seconds),
        ariaTimeLeft: seconds % 30 === 0 ? secondsToTime(seconds, true) : currentState.ariaTimeLeft,
        leftSeconds: seconds,
      };
    });
  };

  const startCountDownTimer = () => {
    if (
      prevState.current.showCountDownModal ||
      prevState.current.showForceSignoutModal ||
      prevState.current.showForceSignOutOnQUser ||
      prevState.current.showConfirmSignoutModal
    ) {
      return;
    }

    if (countDownTimer.current) {
      clearInterval(countDownTimer.current);
      countDownTimer.current = 0;
    }

    setState((currentState) => ({
      ...currentState,
      leftSeconds: COUNT_DOWN_SECONDS,
      timeLeft: secondsToTime(COUNT_DOWN_SECONDS),
      ariaTimeLeft: secondsToTime(COUNT_DOWN_SECONDS, true),
      showCountDownModal: true,
    }));

    countDownTimer.current = window.setInterval(countDown, COUNT_DOWN_INTERVAL);
  };

  const checkIdle = () => {
    if (
      prevState.current.showCountDownModal ||
      prevState.current.showForceSignoutModal ||
      prevState.current.showForceSignOutOnQUser ||
      prevState.current.showConfirmSignoutModal
    ) {
      return;
    }
    const currentTime = new Date().getTime();
    const idleSeconds = Math.floor(
      (currentTime - prevState.current.lastActiveTime.getTime()) / 1000
    );
    if (prevState.current.isAuthenticated) {
      const forceSignoutTimeout = LOGGED_IN_USER_TIMEOUT_SECONDS + COUNT_DOWN_SECONDS;
      if (idleSeconds > forceSignoutTimeout) {
        setSessionContext(SessionContextTypes.SessionTimeout);
        setState((currentState) => ({
          ...currentState,
          showCountDownModal: false,
        }));
      } else if (idleSeconds > LOGGED_IN_USER_TIMEOUT_SECONDS) {
        startCountDownTimer();
      }
    }
  };

  const startIdleCheck = () => {
    idleCheckTimer.current = window.setInterval(checkIdle, IDLE_CHECK_INTERVAL);
  };

  React.useEffect(() => {
    attachEvents();
    startIdleCheck();
    return () => {
      removeEvents();

      if (idleCheckTimer.current) {
        clearInterval(idleCheckTimer.current);
        idleCheckTimer.current = 0;
      }
    };
  }, []);

  React.useEffect(() => {
    prevState.current = {
      isAuthenticated,
      lastActiveTime: state.lastActiveTime,
      showCountDownModal: state.showCountDownModal,
      showForceSignoutModal: state.showForceSignoutModal,
      showForceSignOutOnQUser: state.showForceSignOutOnQUser,
      showConfirmSignoutModal: state.showConfirmSignoutModal,
    };
  }, [
    isAuthenticated,
    state.showCountDownModal,
    state.showForceSignoutModal,
    state.showForceSignOutOnQUser,
    state.showConfirmSignoutModal,
    state.lastActiveTime,
  ]);

  React.useEffect(() => {
    async function handleAuthState() {
      if (!isAuthenticated && sessionContext !== SessionContextTypes.None) {
        setSessionContext(SessionContextTypes.None);
      }

      if (isAuthenticated && sessionContext === SessionContextTypes.ShowConfirmSignOut) {
        setState({ ...state, showConfirmSignoutModal: true });
      }

      if (isAuthenticated && sessionContext === SessionContextTypes.SignOut) {
        setState({
          ...state,
          showConfirmSignoutModal: false,
          showForceSignoutModal: false,
          showForceSignOutOnQUser: false,
        });
        await signOut();
        router
          .push(`/${language}/my-event/sign-in/`)
          .catch((error: string | Error | Record<string, unknown>) =>
            logError('SESSION_MODAL', error, 'failed to router.push to my events sign-in page')
          );
      }

      if (isAuthenticated && sessionContext === SessionContextTypes.SignOutAndStayOnPage) {
        setState({
          ...state,
          showConfirmSignoutModal: false,
          showForceSignoutModal: false,
          showForceSignOutOnQUser: false,
        });
        await signOut();

        const logoEl = document.querySelector('#hiltonLogo') as HTMLAnchorElement;

        if (logoEl) {
          logoEl.focus();
        }
      }

      if (isAuthenticated && sessionContext === SessionContextTypes.SessionTimeout) {
        setState({
          ...state,
          ...(isCorpUser ? { showForceSignOutOnQUser: true } : { showForceSignoutModal: true }),
        });
        await signOut();
      }
    }
    handleAuthState().catch((error: string | Error | Record<string, unknown>) =>
      logError('SESSION_MODAL', error, 'failed to handle auth state')
    );
  }, [sessionContext, isAuthenticated]);

  let title: string | null = null;
  let body: React.ReactNode = null;
  let handleExit = resetCountDownTimer;
  let buttons: IModalButton[] = [];

  // for loggedIn users countdown
  if (state.showCountDownModal) {
    title = t('modal.title.whereYouGo');
    body = (
      <>
        {t('modal.body.sessionExpireTextStart')}{' '}
        <span className="text-primary">{state.timeLeft}</span>
        {t('modal.body.sessionExpireTextEnd')}
      </>
    );
    buttons = [
      {
        e2eId: 'Ok',
        buttonText: t('buttonsAndLinks.ok'),
        buttonStyle: 'solid',
        handler: resetCountDownTimer,
      },
    ];
  }

  // for after force sign out of loggedIn user
  if (state.showForceSignoutModal) {
    const { pathname } = router;

    title = t('modal.title.welcomeBack');
    handleExit = async () => {
      await router.push(`/${language}/my-event/sign-in/`);
      resetCountDownTimer();
    };
    buttons = [
      {
        e2eId: 'SignIn',
        buttonText: t('buttonsAndLinks.signIn'),
        buttonStyle: 'solid',
        handler: handleExit,
      },
    ];

    switch (pathname) {
      case '/my-event/sign-in':
        body = t('modal.body.protectPrivacySignInText');
        break;
      case '/attend-my-event/[event]':
      case '/attend-my-event/edit/[event]':
        handleExit = () => {
          window.location.href = signInUrl;
        };
        body = t('modal.body.protectPrivacySignOutText');
        buttons = [
          {
            e2eId: 'SignIn',
            buttonText: t('buttonsAndLinks.signIn'),
            buttonStyle: 'solid',
            handler: handleExit,
          },
          {
            e2eId: 'Ok',
            buttonText: t('buttonsAndLinks.ok'),
            buttonStyle: 'hollow',
            handler: () => {
              sessionStorage.clear();
              window.location.reload();
            },
          },
        ];
        break;
      case '/my-event/confirmation':
        const eventPageURL = eventData?.event?.content?.url || '/';
        handleExit = () => {
          window.location.href = eventPageURL;
        };
        body = t('modal.body.protectPrivacySignOutText');
        buttons = [
          {
            e2eId: 'viewEventPage',
            buttonText: t('buttonsAndLinks.viewEventPage'),
            buttonStyle: 'solid',
            handler: handleExit,
          },
        ];
        break;
      default:
        body = t('modal.body.protectPrivacyText');
        break;
    }
  }

  // for after force sign out of loggedIn OnQ user
  if (state.showForceSignOutOnQUser) {
    handleExit = () => {
      window.location.href = lobbySignInURL;
    };
    title = t('modal.title.welcomeBack');
    body = t('modal.body.protectPrivacyOnQUserText');
    buttons = [
      {
        e2eId: 'SignInLobby',
        buttonText: t('buttonsAndLinks.signInLobby'),
        buttonStyle: 'solid',
        handler: handleExit,
      },
    ];
  }

  // for confirm sign out
  if (state.showConfirmSignoutModal) {
    title = t('modal.title.confirmSignOut');
    body = t('modal.body.sessionRequiredText');
    buttons = [
      {
        e2eId: 'SignOut',
        buttonText: t('buttonsAndLinks.signOut'),
        buttonStyle: 'solid',
        handler: () => {
          setSessionContext(SessionContextTypes.SignOut);
        },
      },
      {
        e2eId: 'GoBack',
        buttonText: t('buttonsAndLinks.goBack'),
        buttonStyle: 'hollow',
        handler: resetCountDownTimer,
      },
    ];
  }

  return state.showCountDownModal ||
    state.showForceSignOutOnQUser ||
    state.showForceSignoutModal ||
    state.showConfirmSignoutModal ? (
    <DialogOverlay
      data-testid="sessionModalDialog"
      className="dialog-overlay leading-normal"
      onDismiss={handleExit}
    >
      <DialogContent
        aria-label={`${title}, ${t('accessibility.modal')}`}
        className="bg-bg relative top-1/2 mx-auto max-w-md -translate-y-1/2 px-6 py-14 text-center"
      >
        <React.Fragment>
          {state.showCountDownModal && (
            <div className="sr-only" aria-live="polite" aria-atomic="true">
              {t('modal.body.sessionExpireTextStart')}
              {state.ariaTimeLeft}
              {t('modal.body.sessionExpireTextEnd')}
            </div>
          )}
          <div data-testid="sessionModalDialogBox">
            {state.showConfirmSignoutModal ? (
              <h2
                className="mb-4 block px-5 text-xl font-bold leading-tight"
                data-testid="sessionModalDialogBoxHeading"
              >
                {title}
              </h2>
            ) : (
              <h1
                className="mb-4 block p-0 text-xl font-bold leading-tight"
                data-testid="sessionModalDialogBoxHeading"
              >
                {title}
              </h1>
            )}
            <p
              className="mb-8 leading-tight"
              data-testid="sessionModalDialogBoxBody"
              {...(state.showCountDownModal ? { 'aria-hidden': true } : {})}
            >
              {body}
            </p>
          </div>
          {buttons.map((b) =>
            b.buttonStyle === 'solid' ? (
              <button
                type="button"
                key={b.e2eId}
                data-testid={`dialogBox${b.e2eId}Button`}
                onClick={b.handler}
                className="btn btn-primary mx-auto my-3 h-[54px] w-4/5 p-3 text-xl"
              >
                {b.buttonText}
              </button>
            ) : (
              <button
                type="button"
                key={b.e2eId}
                data-testid={`dialogBox${b.e2eId}Button`}
                onClick={b.handler}
                className="btn btn-primary-outline bg-bg text-primary mx-auto my-1 h-[54px] w-4/5 p-1 text-xl"
              >
                {b.buttonText}
              </button>
            )
          )}
        </React.Fragment>
      </DialogContent>
    </DialogOverlay>
  ) : null;
};

export default SessionModal;
